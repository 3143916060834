<template>
  <div class="login col-6 m-auto mt-5" @keyup.enter="sendMail">
    <div class="form-group">
      <label for="exampleInputEmail1">{{ $t('resetPassword.emailAddress') }}</label>
      <Input id="email"
               :placeholder="$t('login.email')"
               :error-messages="errors.email"
               type="text"
               :value="dataReset.email"
               @keyup="onHandleChange('email', dataReset.email)"
               @onHandleChangeValue="(value) => onHandleChange('email', value)"/>
    </div>
    <Button @click="sendMail" class-name="btn btn-primary">
        {{ $t('resetPassword.send') }}
      </Button>
  </div>
</template>

<script>
import AuthRepository from "@/services/api/AuthRepository";
import { SUCCESS } from "../../constants";
import Input from "../../components/common/Input";
import { resetPassFormSchema } from "../../common/schemaValidation";
import Button from "../../components/common/Button";
import { mapMutations } from "vuex";

export default {
    name: "Login",
    components: {
        Input,
        Button
    },
    data() {
        return {
            dataReset: {
                email: ""
            },
            errors: {
                email: ""
            },
        };
    },
    methods: {
        ...mapMutations({
            setIsLoading: "common/setIsLoading",
        }),
        sendMail() {
            resetPassFormSchema
                .validate(this.dataReset, { abortEarly: false })
                .then(() => {
                    this.setIsLoading(true);
	                this.errors = {
		                email: ""
	                };
                    AuthRepository.sendMail(this.dataReset)
                        .then((res) => {
                            this.setIsLoading(false);
                            if (!res.status || res.status !== SUCCESS) {
                                this.$toast.error(`${this.dataReset.email}が存在しません。`)
                            }
                            if (res.status === SUCCESS) {
                                this.$toast.success("パスワード再設定メールを送信しました。", {})
                                this.$router.push({ name: "resetPasswordConfirm", params: { email: res.data.data } });
                            }
                        })
                })
                .catch(err => {
                    this.setIsLoading(false);
                    err.inner.forEach(error => {
                        this.errors[error.path] = error.message;
                    });
                });
        },
        onHandleChange(field, value) {
            this.dataReset[field] = value.trim()
        },
    }
};
</script>
